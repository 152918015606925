@require '../base/colors';

.container
	display inline-block
	position relative

.popover
	position absolute
	z-index 100
	top calc(100% + 12px)
	right 25%
	padding 10px
	background-color $popover
	min-width 25rem

.arrow
	position absolute
	top -10px
	right 0
	border 5px solid $popover
	border-top-color transparent
	border-left-color transparent
