.row {
  background-color: #f5f8fa;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
@media (min-width: 768px) {
  .row {
    flex-wrap: nowrap;
  }
}
.search {
  margin-bottom: 0px;
  position: relative;
  align-items: center;
  display: flex;
  background-color: #f5f8fa;
}
.searchIcon {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: calc(50% - 10px);
}
.searchIcon svg {
  fill: #28e6c4;
}
@media (min-width: 768px) {
  .searchIcon {
    width: 30px;
    height: 30px;
    left: 30px;
    top: calc(50% - 15px);
  }
}
.searchBar {
  height: 100%;
  width: 100%;
  outline: none !important;
  border: none !important;
  text-indent: 60px;
  background-color: #fff;
  color: #728a96;
  font-size: 20px;
  padding: 10px 0px;
  margin-bottom: 0px;
}
.searchBar::placeholder {
  color: #728a96;
  font-weight: 100;
}
@media (min-width: 768px) {
  .searchBar {
    background-color: #f5f8fa;
    padding: 0px;
    text-indent: 70px;
  }
}
.filter {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filter:after {
  content: '';
  width: 1px;
  height: 60%;
  position: absolute;
  top: 20%;
  right: 0;
  background-color: #606c76;
  opacity: 0.5;
}
.filter:last-child:after {
  display: none;
}
.filter:last-child:after .filterPanel {
  right: 0;
  left: auto;
}
.filterMaximized {
  position: static;
}
.filterHeader {
  padding: 1.65em 1em;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.filterHeader:hover {
  cursor: pointer;
}
.filterPanel {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f5f8fa;
  padding: 0.75em 1em;
  min-width: 150%;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  text-align: left;
  width: 100%;
  min-width: 15em;
  color: #606c76;
  z-index: 5;
}
.filterPanel select:focus {
  border-color: #28e6c4;
}
.filterPanelMaximized {
  width: 75%;
  left: 25%;
  height: 600px;
  overflow-y: auto;
}
.filterLabel {
  white-space: nowrap;
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.filterLabel:hover {
  cursor: pointer;
}
@media (min-width: 768px) {
  .filterLabel {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    font-size: 14px;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
.active {
  background-color: #28e6c4;
  color: #fff;
}
.active:after {
  width: 0px;
}
.filterTitle {
  color: #606c76;
  display: inline-block;
}
.filterArrow {
  display: inline-block;
  width: 16px;
  margin-left: 5px;
  height: 1.5em;
}
.filterArrow svg {
  fill: #606c76;
}
.upArrow {
  transform: rotateX(180deg);
  transform-origin: 50%;
}
.upArrow svg {
  fill: #fff;
}
.bulletContainer {
  position: relative;
  top: 0.1em;
  margin-right: 0.5em;
  display: inline-block;
}
@media (min-width: 768px) {
  .bulletContainer {
    margin-right: 1em;
  }
}
.bullet {
  background-color: #2d4754;
  border-radius: 50%;
  height: 1em;
  width: 1em;
}
.bulletActive {
  background-color: #28e6c4;
}
.desktopMenu {
  display: flex !important;
}
.minmaxUnits {
  margin-bottom: 7px;
}
.minmaxUnits > * {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  max-width: 100%;
}
@media (min-width: 1500px) {
  .minmaxUnits > * {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }
}
.inputWrapper {
  justify-content: space-between;
  align-items: center;
}
.inputWrapper input {
  width: 40%;
  margin-bottom: 0px;
  border-radius: 0px;
  background-color: #fff;
  border-color: #d1d1d1;
  text-align: center;
}
.mobileMenuRow {
  background-color: #062331;
  color: #606c76;
  display: flex;
  width: 100%;
}
.mobileMenuRow div:nth-child(2):after {
  display: none;
}
.menuCol {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 15px 0px;
  font-size: 14px;
  font-weight: 500;
  position: relative;
}
.menuCol:after {
  content: '';
  width: 1px;
  height: 60%;
  position: absolute;
  top: 20%;
  right: 0;
  background-color: #606c76;
  opacity: 0.5;
}
.icon {
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.icon svg {
  height: 25px;
  width: 25px;
  fill: #768487;
}
.mobileMenu {
  background-color: #1b3743;
  padding: 10px 35px 30px 35px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  height: calc(100vh - 100% - 80px);
}
.mobileHeader {
  width: 100%;
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
}
.mobileMenuBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  height: 60px;
}
.filterNumber {
  color: #28e6c4;
  margin-right: 5px;
}
.active .filterNumber {
  color: #fff;
}
.filterWithNumber {
  display: inline-block;
}
.advancedTab {
  cursor: pointer;
  padding: 0.5em 1em;
  text-align: right;
  border-right: 4px solid transparent;
}
.advancedTabActive {
  background-color: rgba(0,0,0,0.1);
  border-color: #28e6c4;
}
.advancedContent {
  padding: 2rem 3rem;
}
.advancedColumn {
  max-height: 280px;
  margin-bottom: 20px;
  overflow: auto;
  padding-right: 15px;
}
