.cell {
  background-color: #fff;
  transition: background-color 0.35s;
}
.row:hover {
  cursor: pointer;
}
.row:hover .cell {
  background-color: #f5f8fa;
}
