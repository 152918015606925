@require './variables.styl'

.hiddenXs
	display none
	@media $screen-sm
		display flex
.showXs
	display flex
	@media $screen-sm
		display none
