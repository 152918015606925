// Style helper to ensure an element fills the available space in the layout.
@require '../base/colors.styl'

.fill
	position absolute
	top 0
	bottom 0
	right 0
	left 0

input[type='search']:focus
	border-color $activeItem
