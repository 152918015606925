@require '../base/colors.styl'
@require '../base/variables.styl'

.row
	background-color #F5F8FA
	margin-right 0 !important
	margin-left 0 !important

	@media $screen-sm
		flex-wrap nowrap

.search
	margin-bottom 0px
	position relative
	align-items center
	display flex
	background-color #F5F8FA

.searchIcon
	width 20px
	height 20px
	svg
		fill $activeItem
	position absolute
	left 20px
	top calc(50% - 10px)
	@media $screen-sm
		width 30px
		height 30px
		left 30px
		top calc(50% - 15px)

.searchBar
	height 100%
	width 100%
	outline none !important
	border none !important
	text-indent 60px
	background-color #fff
	color #728A96
	font-size 20px
	padding 10px 0px
	margin-bottom 0px
	&::placeholder
		color #728A96
		font-weight 100
	@media $screen-sm
		background-color #F5F8FA
		padding 0px
		text-indent 70px

.filter
	position relative
	display flex
	justify-content center
	align-items center
	&:after
		content ''
		width 1px
		height 60%
		position absolute
		top 20%
		right 0
		background-color #606c76
		opacity .5
	&:last-child:after
		display none

		.filterPanel
			right 0
			left auto

.filterMaximized
	position static

.filterHeader
	padding 1.65em 1em
	width 100%
	text-align center
	text-transform uppercase
	font-size 14px
	display flex
	justify-content center
	align-items center
	&:hover
		cursor pointer

.filterPanel
	position absolute
	top 100%
	left 0
	background-color #F5F8FA
	padding 0.75em 1em
	min-width 150%
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	text-align left
	width 100%
	min-width 15em
	color #606c76
	z-index 5
	select:focus
		border-color $activeItem

.filterPanelMaximized
	width 75%
	left 25%
	height 600px
	overflow-y auto

.filterLabel
	white-space nowrap
	-ms-flex-preferred-size 50%
	flex-basis 50%
	max-width 50%
	font-size 12px
	margin-bottom 10px
	text-transform uppercase
	&:hover
		cursor pointer
	@media $screen-sm
		-ms-flex-preferred-size 100%
		flex-basis 100%
		font-size 14px
		max-width:100%
		margin-bottom 10px
.active
	background-color $activeItem
	color #fff
	&:after
		width 0px
.filterTitle
	color #606c76;
	display inline-block

.filterArrow
	display inline-block
	width 16px
	margin-left 5px
	height 1.5em
	svg
		fill #606c76

.upArrow
	transform rotateX(180deg)
	transform-origin 50%
	svg
		fill #fff
.bulletContainer
	position relative
	top 0.1em
	margin-right 0.5em
	display inline-block
	@media $screen-sm
		margin-right 1em

.bullet
	background-color #2D4754
	border-radius 50%
	height 1em
	width 1em
.bulletActive
	background-color $activeItem

.desktopMenu
	display flex !important

.minmaxUnits
	margin-bottom 7px
	> *
		-ms-flex-preferred-size 100%
		flex-basis 100%
		max-width 100%
		@media $screen-xlg
			-ms-flex-preferred-size 50%
			flex-basis 50%
			max-width 50%

.inputWrapper
	justify-content space-between
	align-items center
	input
		width 40%
		margin-bottom 0px
		border-radius 0px
		background-color #fff
		border-color #d1d1d1
		text-align center


//Mobile Menu
.mobileMenuRow
	background-color $searchBg
	color #606c76
	display flex
	width 100%
	div:nth-child(2)
		&:after
			display none

.menuCol
	justify-content center
	align-items center
	display flex
	padding 15px 0px
	font-size 14px
	font-weight 500
	position relative
	&:after
		content ''
		width 1px
		height 60%
		position absolute
		top 20%
		right 0
		background-color #606c76
		opacity .5

.icon
	height 25px
	width 25px
	display inline-block
	vertical-align middle
	margin-right 5px
	svg
		height 25px
		width 25px
		fill #768487

.mobileMenu
	background-color #1B3743
	padding 10px 35px 30px 35px
	overflow-y scroll
	-webkit-overflow-scrolling: touch;
	position absolute
	top 100%
	left 0
	right 0
	height calc(100vh - 100% - 80px)

.mobileHeader
	width 100%
	color #fff
	text-align center
	margin-bottom 15px
	margin-top 30px

.mobileMenuBottom
	position fixed
	bottom 0
	left 0
	right 0
	background-color #fff
	height 60px
.filterNumber
	color $activeItem
	margin-right 5px

.active
	.filterNumber
		color #fff

.filterWithNumber
	display inline-block

.advancedTab
	cursor pointer
	padding 0.5em 1em
	text-align right
	border-right 4px solid transparent

.advancedTabActive
	background-color rgba(0,0,0,0.10)
	border-color $activeItem

.advancedContent
	padding 2rem 3rem

.advancedColumn
	// max-width 30%
	max-height 280px
	margin-bottom 20px
	overflow auto
	padding-right 15px
