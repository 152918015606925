.map {
  height: 100%;
  position: absolute !important;
  width: 100%;
}
.relative {
  position: relative;
}
.fullHeight {
  height: 100%;
}
.zoomContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}
.zoomLink {
  text-align: center;
  width: 42px;
  height: 42px;
  font-size: 32px;
  color: #7a8b93;
  background-color: #012533;
  box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.zoomLink:hover {
  cursor: pointer;
}
.zoomLink svg {
  height: 52px;
  width: 52px;
  fill: #042433;
}
.zoomLink svg .cls-2 {
  fill: #fff;
}
.marginTop {
  margin-top: 20px;
}
