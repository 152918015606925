@require '../base/colors'
@require '../../../variables.styl'

.pills
	// margin 0 2.5em
	display inline-block
	@media $mobile
		margin-top 10px
		display flex
		justify-content center
		align-items center
		max-width 100%
		flex-wrap wrap

.pill
	background-color $searchBg
	border-radius 2em
	padding 0 1.5em
	margin-bottom 0
	@media $mobile
		margin-bottom 5px

.pill + .pill
	margin-left .5em

.active
	background-color $activeItem
	border-color $activeItem
