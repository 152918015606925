.fill {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
input[type='search']:focus {
  border-color: #28e6c4;
}
