.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em 1.5em;
  background-color: #f5f8fa;
}
.filters {
  max-width: 50%;
}
.searchButtonContainer {
  margin-top: 30px;
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
}
@media (max-width: 767px) {
  .searchButtonContainer {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 767px) {
  .searchButtonContainer button {
    width: 100%;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
  }
}
.resultsToShowWrapper {
  display: flex;
  align-items: center;
}
.resultsToShowWrapper select {
  margin-left: 10px;
  margin-bottom: 0px;
}
