.propertyRow {
  margin-bottom: 5px;
}
.propertyRow:hover {
  cursor: pointer;
  box-shadow: 0px 5px 50px rgba(0,0,0,0.1), 0px -5px 50px rgba(0,0,0,0.1);
}
.photoContainer {
  display: flex;
}
.propertyImage {
  background-size: cover;
  height: 230px;
  width: 100%;
  flex: 1 1 auto;
}
@media (min-width: 768px) {
  .propertyImage {
    height: auto;
  }
}
.detailColumn {
  padding: 0px 15px;
  position: relative;
}
@media (min-width: 768px) {
  .detailColumn:after,
  .detailColumn:before {
    display: none;
  }
}
.detailColumn:after,
.detailColumn:before {
  bottom: 100%;
  left: 10%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.detailColumn:after {
  border-color: rgba(255,255,255,0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.detailColumn:before {
  border-color: rgba(255,255,255,0);
  border-bottom-color: #fff f;
  border-width: 18px;
  margin-left: -18px;
}
.innerContainer {
  padding: 20px 20px;
}
.price {
  color: #42bfa3;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .price {
    font-size: 24px;
  }
}
.description {
  margin-bottom: 10px;
  font-size: 16px;
  color: $darkBlue;
}
.infoRow {
  margin-bottom: 10px;
}
.infoRow > * {
  margin-bottom: 7px;
}
.infoLabel {
  color: #062331;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
.info {
  color: #7f8384;
  font-size: 16px;
}
.address {
  color: #062331;
  font-weight: bold;
  margin-bottom: 0px;
  font-size: 14px;
}
@media (min-width: 768px) {
  .bottomBorder {
    position: absolute;
    bottom: -2.5px;
    height: 1px;
    background-color: #ccc;
    width: calc(100% - 40px);
  }
}
