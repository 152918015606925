.chartContainer {
  width: 400px;
  height: 400px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .chartContainer {
    width: 300px;
    height: 300px;
  }
}
