@require '../base/colors.styl'
@require '../base/variables.styl'
.icon
	display inherit
	height 100% //Stlye height and width will be styled where the icon is
	width 100%
	fill $darkBlue

.typeIcon
	width 15px
	fill $darkBlue
	display inline-block
	height 15px
	vertical-align text-top
	margin-right 10px
	@media $screen-sm
		height 20px
		width 20px
		vertical-align middle
