@require '../base/variables.styl'
@require '../base/colors.styl'


.cell
	background-color $mainBg
	transition background-color 0.35s

.row
	&:hover
		cursor pointer

		.cell
			background-color $hoverRow

