.propertiesWrapper {
  position: relative;
}
@media (min-width: 768px) {
  .propertiesWrapper {
    width: 33%;
  }
}
.properties {
  background-color: #fff;
  z-index: 1;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translateX(0vw);
  transition: transform 0.5s;
  font-size: 1.3rem;
}
@media (min-width: 768px) {
  .properties {
    width: 50vw;
    font-size: inherit;
  }
  .collapsed .properties {
    transform: translateX(-16.666vw);
  }
}
.shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 5px 0px 8px rgba(0,0,0,0.1);
  z-index: 0;
}
.shadowTwo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 17px 0px 20px rgba(0,0,0,0.1);
}
.zeroState {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #042433;
}
@media (min-width: 768px) {
  .zeroState {
    transform: translateX(8.33333vw);
    transition: transform 0.5s;
  }
}
@media (min-width: 768px) {
  .zeroClosed {
    transform: translateX(0);
  }
}
.zeroStateHeader {
  color: #092431;
  font-weight: 500;
  font-size: 15px;
}
@media (min-width: 768px) {
  .zeroStateHeader {
    font-size: 20px;
  }
}
.propertiesScroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
.wrap {
  display: flex;
  align-items: stretch;
  height: 100%;
}
.collapseButton {
  display: none;
}
@media (min-width: 768px) {
  .collapseButton {
    position: absolute;
    height: 100px;
    width: 30px;
    background-color: #fff;
    right: -29px;
    top: calc(50% - 50px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
  }
  .collapseButton:after {
    background: inherit;
    bottom: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(-35deg);
    transform-origin: 100%;
    z-index: -1;
  }
  .collapseButton:before {
    background: inherit;
    top: 0;
    content: '';
    display: block;
    height: 50%;
    left: 0;
    position: absolute;
    right: 0;
    transform: skewY(35deg);
    transform-origin: 100%;
    z-index: -1;
  }
  .collapseButton:hover {
    cursor: pointer;
  }
}
.collapseArrow {
  margin-bottom: 0px;
  fill: #31ceb2;
  width: 12px;
  line-height: 0px;
}
.collapseArrow svg {
  fill: #31ceb2;
}
.closed svg {
  transform: rotate(180deg);
}
.mapWrapper {
  height: 100%;
  position: relative;
  width: 100%;
}
