.container {
  display: inline-block;
  position: relative;
}
.popover {
  position: absolute;
  z-index: 100;
  top: calc(100% + 12px);
  right: 25%;
  padding: 10px;
  background-color: #dadee0;
  min-width: 25rem;
}
.arrow {
  position: absolute;
  top: -10px;
  right: 0;
  border: 5px solid #dadee0;
  border-top-color: transparent;
  border-left-color: transparent;
}
