.icon {
  display: inherit;
  height: 100%;
  width: 100%;
  fill: #042433;
}
.typeIcon {
  width: 15px;
  fill: #042433;
  display: inline-block;
  height: 15px;
  vertical-align: text-top;
  margin-right: 10px;
}
@media (min-width: 768px) {
  .typeIcon {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}
