@require '../../../variables.styl'

.nav
	display flex
	justify-content space-between
	align-items center
	padding 1em 1.5em
	background-color #F5F8FA

.filters
	max-width 50%
.searchButtonContainer
	margin-top 30px
	// margin-right -0.5rem
	flex-direction row
	justify-content flex-end
	display flex
	@media $mobile
		justify-content center
		display flex
		flex-direction column


	button
		@media $mobile
			width 100%
			margin-left 5px
			margin-right 5px
			margin-top 5px

.resultsToShowWrapper
	display flex
	align-items center
	select
		margin-left 10px
		margin-bottom 0px
