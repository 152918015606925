.pills {
  display: inline-block;
}
@media (max-width: 767px) {
  .pills {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    flex-wrap: wrap;
  }
}
.pill {
  background-color: #062331;
  border-radius: 2em;
  padding: 0 1.5em;
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .pill {
    margin-bottom: 5px;
  }
}
.pill + .pill {
  margin-left: 0.5em;
}
.active {
  background-color: #28e6c4;
  border-color: #28e6c4;
}
