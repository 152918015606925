.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.header {
  display: flex;
  flex-shrink: 0;
}
.search {
  position: relative;
  z-index: 2;
  flex-shrink: 0;
  max-width: 100%;
  background-color: #062331;
}
.main {
  flex-grow: 1;
  position: relative;
}
