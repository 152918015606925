@require '../../../variables.styl'


.chartContainer
	width 400px
	height 400px
	margin-left auto
	margin-right auto
	@media $mobile
		width 300px
		height 300px
