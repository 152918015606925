@require '../base/variables.styl'

.propertyRow
	margin-bottom 5px
	&:hover
		cursor pointer
		box-shadow: 0px 5px 50px rgba(0,0,0,0.1), 0px -5px 50px rgba(0,0,0,0.1);

.photoContainer
	display flex
.propertyImage
	background-size cover
	height 230px
	width 100%
	flex 1 1 auto
	@media $screen-sm
		height auto
.detailColumn
	padding 0px 15px
	position relative
@media $screen-sm
	.detailColumn:after, .detailColumn:before
		display none
.detailColumn:after, .detailColumn:before
	bottom 100%
	left 10%
	border solid transparent
	content " "
	height 0
	width 0
	position absolute
	pointer-events none


.detailColumn:after
	border-color rgba(255, 255, 255, 0)
	border-bottom-color #ffffff
	border-width 12px
	margin-left -12px

.detailColumn:before
	border-color rgba(255, 255, 255, 0)
	border-bottom-color #fffffff
	border-width 18px
	margin-left -18px



.innerContainer
	padding 20px 20px
.price
	color #42BFA3
	font-size 18px
	font-weight 400
	margin-bottom 10px
	@media $screen-sm
		font-size 24px
.description
	margin-bottom 10px
	font-size 16px
	color $darkBlue
.infoRow
	margin-bottom 10px
	> *
		margin-bottom 7px
.infoLabel
	color: #062331;
	font-weight: bold;
	margin-bottom: 0px;
	font-size: 14px;
.info
	color #7F8384
	font-size 16px
.address
	color #062331
	font-weight bold
	margin-bottom 0px
	font-size 14px

.bottomBorder
	@media $screen-sm
		position absolute
		bottom -2.5px
		height 1px
		background-color #ccc
		width calc(100% - 40px)
